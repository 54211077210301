import React from 'react';
import { Layout } from '../components/layout';

const Book = () => {
    const metaProps = {
        title: 'Zarezerwuj wizytę Beauty-Spot',
    };

    React.useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = '/js/booksy.js';
        document.getElementById('gatsby-focus-wrapper').appendChild(script);
    }, []);

    return (
        <Layout metaProps={metaProps}>
            <section className="section-30 section-sm-top-35 section-xl-top-120 bg-image bg-image-4">
                <div className="shell shell-fluid">
                    <div className="range range-condensed">
                        <div className="cell-xs-12 cell-xl-preffix-1 cell-xl-11">
                            <p className="h6 text-italic font-serif text-regular">Dla naszych klientów</p>
                            <p className="h1">Zarezerwuj wizytę</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-30">
                <div id='beauty-spot-booksy-widget'>

                </div>
            </section>
        </Layout>
    );
};

export default Book;